<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.1998 8.09995C7.91589 8.09995 8.60265 7.81549 9.10899 7.30914C9.61534 6.80279 9.89981 6.11604 9.89981 5.39995C9.89981 4.68387 9.61534 3.99711 9.10899 3.49076C8.60265 2.98441 7.91589 2.69995 7.1998 2.69995C6.48372 2.69995 5.79696 2.98441 5.29062 3.49076C4.78427 3.99711 4.4998 4.68387 4.4998 5.39995C4.4998 6.11604 4.78427 6.80279 5.29062 7.30914C5.79696 7.81549 6.48372 8.09995 7.1998 8.09995ZM7.1998 9.89995C8.63197 9.89995 10.0055 10.4689 11.0182 11.4816C12.0309 12.4943 12.5998 13.8678 12.5998 15.3H1.7998C1.7998 13.8678 2.36873 12.4943 3.38143 11.4816C4.39412 10.4689 5.76764 9.89995 7.1998 9.89995Z"
      fill="currentColor"
    />
    <path
      d="M16.6501 6.65991C16.6501 6.43459 16.5538 6.22541 16.394 6.07632C16.2352 5.92828 16.0261 5.84998 15.8141 5.84998C15.6022 5.84998 15.3931 5.92823 15.2343 6.07628L12.7256 8.41567L11.7613 7.51629L11.7555 7.51107C11.5961 7.36752 11.3888 7.29271 11.1792 7.29441C10.9695 7.29611 10.7633 7.37429 10.6063 7.52072C10.4482 7.66811 10.3522 7.87433 10.3501 8.09708C10.3481 8.31987 10.4402 8.52801 10.5959 8.67828L12.1459 10.1237C12.3047 10.2718 12.5137 10.35 12.7256 10.35C12.9376 10.35 13.1467 10.2717 13.3055 10.1237L16.394 7.2435C16.5538 7.09442 16.6501 6.88524 16.6501 6.65991Z"
      fill="currentColor"
    />
  </svg>
</template>
